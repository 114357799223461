@mixin mx-ag-grid-customization {
  /* Table border */
  --ag-border-color: var(--ion-border-color); // Sets the color of the table border
  --ag-borders: solid 1px var(--ion-border-color); // Defines the border style and thickness for the table

  /* Row and cell borders */
  --ag-row-border-style: solid; // Sets the style of the row borders
  --ag-row-border-width: 1px; // Sets the thickness of the row borders
  --ag-row-border-color: var(--ion-border-color); // Sets the color of the row borders
  --ag-cell-horizontal-border: solid var(--ion-border-color); // Defines the border style and color for cells

  /* Table background */
  --ag-background-color: var(--ion-background-color); // Sets the background color of the table
  --ag-foreground-color: var(--ion-text-color); // Sets the color of the text and icons in primary UI elements

  /* Row or cell background */
  --ag-odd-row-background-color: var(--ion-background-color-step-50); // Sets the background color for odd rows
  --ag-selected-row-background-color: var(--ion-color-primary-shade); // Sets the background color for selected rows
  --ag-row-hover-color: var(--ion-color-primary-tint); // Sets the background color when hovering over rows

  /* Header background */
  --ag-header-background-color: var(--ion-color-main-header-bg); // Sets the background color for headers
  --ag-header-foreground-color: var(--ion-color-main-header-bg-contrast); // Sets the color of text and icons in the header
  --ag-header-cell-hover-background-color: var(--ion-color-primary-tint); // Sets the rollover color for header cells
  --ag-header-cell-moving-background-color: var(--ion-color-primary-shade); // Sets the color applied to header cells when the column is being dragged

  /* Tooltip background */
  --ag-tooltip-background-color: var(--ion-color-surface-background); // Sets the background color for tooltips

  /* Disabled elements */
  --ag-disabled-foreground-color: var(--ion-color-medium); // Sets the color of elements that can't be interacted with because they are in a disabled state
  --ag-input-disabled-background-color: var(--ion-color-light); // Sets the background color of disabled text inputs
  --ag-input-disabled-border-color: var(--ion-color-medium); // Sets the border color of disabled text inputs

  /* Selected tab underline */
  --ag-selected-tab-underline-color: var(--ion-color-primary); // Sets the color of the border drawn under selected tabs
  --ag-selected-tab-underline-width: 2px; // Sets the thickness of the border drawn under selected tabs
  --ag-selected-tab-underline-transition-speed: 250ms; // Sets the duration of the animation used to show and hide the border drawn under selected tabs

  /* Range selection */
  --ag-range-selection-border-color: var(--ion-color-primary); // Sets the color to draw around selected cell ranges
  --ag-range-selection-background-color: var(--ion-color-primary-tint); // Sets the background color of selected cell ranges
  --ag-range-selection-highlight-color: var(--ion-color-primary-shade); // Sets the background color to briefly apply to a cell range when it is copied from or pasted into

  /* Miscellaneous */
  --ag-icon-size: 16px; // Sets the size of square icons and icon-buttons
  --ag-font-family: var(--ion-font-family, sans-serif); // Sets the font family used for all text
  --ag-font-size: 14px; // Sets the default font size for text in the grid
}
