/* Ionic Variables and Theming. */
/* This is just a placeholder file For more info, please see: */
/* https://ionicframework.com/docs/theming/basics */

/* To quickly generate your own theme, check out the color generator */
/* https://ionicframework.com/docs/theming/color-generator */

// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

@forward 'src/theme/partials/font-rubik';

body,
html {
  font-family: 'Rubik', Arial, 'Helvetica Neue', sans-serif; // Font family of the app
}

/** Ionic CSS Variables **/
:root {
  --ion-color-primary: #4361ee;
  --ion-color-primary-rgb: 67, 97, 238;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3b55d1;
  --ion-color-primary-tint: #5671f0;

  --ion-color-secondary: #2196f3;
  --ion-color-secondary-rgb: 33, 150, 243;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #1d84d6;
  --ion-color-secondary-tint: #37a1f4;

  --ion-color-tertiary: #805dca;
  --ion-color-tertiary-rgb: 128, 93, 202;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #7152b2;
  --ion-color-tertiary-tint: #8d6dcf;

  --ion-color-success: #00ab55;
  --ion-color-success-rgb: 0, 171, 85;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #00964b;
  --ion-color-success-tint: #1ab366;

  --ion-color-info: #2196f3;
  --ion-color-info-rgb: 33, 150, 243;
  --ion-color-info-contrast: #000000;
  --ion-color-info-contrast-rgb: 0, 0, 0;
  --ion-color-info-shade: #1d84d6;
  --ion-color-info-tint: #37a1f4;

  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  --ion-color-danger: #e92b44;
  --ion-color-danger-rgb: 233, 43, 68;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cd263c;
  --ion-color-danger-tint: #eb4057;

  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  --ion-color-light: #ececec;
  --ion-color-light-rgb: 245, 248, 229;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d8daca;
  --ion-color-light-tint: #f6f9e8;

  // #region Custom colors
  --ion-color-white: #ffffff;
  --ion-color-white-rgb: 255, 255, 255;
  --ion-color-white-contrast: #000000;
  --ion-color-white-contrast-rgb: 0, 0, 0;
  --ion-color-white-shade: #e0e0e0;
  --ion-color-white-tint: #ffffff;

  --ion-color-black: #000000;
  --ion-color-black-rgb: 0, 0, 0;
  --ion-color-black-contrast: #ffffff;
  --ion-color-black-contrast-rgb: 255, 255, 255;
  --ion-color-black-shade: #000000;
  --ion-color-black-tint: #1a1a1a;

  --ion-color-brand-primary: #f6ff00;
  --ion-color-brand-primary-rgb: 246, 255, 0;
  --ion-color-brand-primary-contrast: #000000;
  --ion-color-brand-primary-contrast-rgb: 0, 0, 0;
  --ion-color-brand-primary-shade: #d8e000;
  --ion-color-brand-primary-tint: #f7ff1a;

  --ion-color-brand-primary-bg: #0e172f;
  --ion-color-brand-primary-bg-rgb: 14, 23, 47;
  --ion-color-brand-primary-bg-contrast: #ffffff;
  --ion-color-brand-primary-bg-contrast-rgb: 255, 255, 255;
  --ion-color-brand-primary-bg-shade: #0c1429;
  --ion-color-brand-primary-bg-tint: #262e44;

  --ion-color-main-header-bg: #0f0c1f;
  --ion-color-main-header-bg-rgb: 15, 12, 31;
  --ion-color-main-header-bg-contrast: #ffffff;
  --ion-color-main-header-bg-contrast-rgb: 255, 255, 255;
  --ion-color-main-header-bg-shade: #0d0b1b;
  --ion-color-main-header-bg-tint: #272435;

  --ion-color-surface-background: #fff;
  --ion-color-surface-background-rgb: 255, 255, 255;
  --ion-color-surface-background-contrast: #000000;
  --ion-color-surface-background-contrast-rgb: 0, 0, 0;
  --ion-color-surface-background-shade: #ebebeb;
  --ion-color-surface-background-tint: #ffffff;
  // #endregion Custom colors

  // #region Stepped Colors
  // https://ionicframework.com/docs/theming/themes#stepped-color-generator
  // TODO: Find all variables that start with the 'var(--ion-color-step-..)' and replace them with the newly added variables

  --ion-background-color: #e4e3ef; // Background color of the entire app
  --ion-background-color-rgb: 228, 227, 239; // Background color of the entire app, rgb format

  --ion-text-color: #0b0b0b; // Text color of the entire app
  --ion-text-color-rgb: 11, 11, 11; // Text color of the entire app, rgb format

  --ion-color-step-50: #d9d8e4; // TODO: In Ionic v8+, we should remove '--ion-color-step..'
  --ion-color-step-100: #cecdd8;
  --ion-color-step-150: #c3c3cd;
  --ion-color-step-200: #b9b8c1;
  --ion-color-step-250: #aeadb6;
  --ion-color-step-300: #a3a2ab;
  --ion-color-step-350: #98979f;
  --ion-color-step-400: #8d8d94;
  --ion-color-step-450: #828288;
  --ion-color-step-500: #78777d;
  --ion-color-step-550: #6d6c72;
  --ion-color-step-600: #626166;
  --ion-color-step-650: #57575b;
  --ion-color-step-700: #4c4c4f;
  --ion-color-step-750: #414144;
  --ion-color-step-800: #363639;
  --ion-color-step-850: #2c2b2d;
  --ion-color-step-900: #212122;
  --ion-color-step-950: #161616;

  --ion-text-color-step-50: #161616;
  --ion-text-color-step-100: #212122;
  --ion-text-color-step-150: #2c2b2d;
  --ion-text-color-step-200: #363639;
  --ion-text-color-step-250: #414144;
  --ion-text-color-step-300: #4c4c4f;
  --ion-text-color-step-350: #57575b;
  --ion-text-color-step-400: #626166;
  --ion-text-color-step-450: #6d6c72;
  --ion-text-color-step-500: #78777d;
  --ion-text-color-step-550: #828288;
  --ion-text-color-step-600: #8d8d94;
  --ion-text-color-step-650: #98979f;
  --ion-text-color-step-700: #a3a2ab;
  --ion-text-color-step-750: #aeadb6;
  --ion-text-color-step-800: #b9b8c1;
  --ion-text-color-step-850: #c3c3cd;
  --ion-text-color-step-900: #cecdd8;
  --ion-text-color-step-950: #d9d8e4;

  --ion-background-color-step-50: #d9d8e4;
  --ion-background-color-step-100: #cecdd8;
  --ion-background-color-step-150: #c3c3cd;
  --ion-background-color-step-200: #b9b8c1;
  --ion-background-color-step-250: #aeadb6;
  --ion-background-color-step-300: #a3a2ab;
  --ion-background-color-step-350: #98979f;
  --ion-background-color-step-400: #8d8d94;
  --ion-background-color-step-450: #828288;
  --ion-background-color-step-500: #78777d;
  --ion-background-color-step-550: #6d6c72;
  --ion-background-color-step-600: #626166;
  --ion-background-color-step-650: #57575b;
  --ion-background-color-step-700: #4c4c4f;
  --ion-background-color-step-750: #414144;
  --ion-background-color-step-800: #363639;
  --ion-background-color-step-850: #2c2b2d;
  --ion-background-color-step-900: #212122;
  --ion-background-color-step-950: #161616;
  // #endregion Stepped Colors

  // #region Application Colors
  // https://ionicframework.com/docs/theming/themes#application-colors
  --ion-backdrop-color: var(--ion-background-color-step-950); // Color of the Backdrop component
  --ion-backdrop-opacity: 0.5; // Opacity of the Backdrop component
  --ion-overlay-background-color: var(--ion-background-color-step-50); // Background color of the overlays

  --ion-card-background: var(--ion-color-surface-background);
  --ion-border-color: var(--ion-background-color-step-100); // Border color
  // --ion-box-shadow-color: var(--ion-background-color-step-50); // Box shadow color

  --ion-tab-bar-background: var(--ion-background-color-step-50); // Background of the Tab Bar
  // --ion-tab-bar-background-focused: #fff; // Background of the focused Tab Bar
  // --ion-tab-bar-border-color: #fff; // Border color of the Tab Bar
  // --ion-tab-bar-color: #fff; // Color of the Tab Bar
  // --ion-tab-bar-color-selected: #fff; // Color of the selected Tab Button

  --ion-toolbar-background: var(--ion-color-surface-background); // Background of the Toolbar
  // --ion-toolbar-border-color: #fff; // Border color of the Toolbar
  // --ion-toolbar-color: #fff; // Color of the components in the Toolbar

  // --ion-toolbar-segment-color: #f00; // Color of the Segment Buttons in the Toolbar
  // --ion-toolbar-segment-color-checked: #f00; // Color of the checked Segment Buttons in the Toolbar
  // --ion-toolbar-segment-background: #f00; // Background of the Segment Buttons in the Toolbar
  // --ion-toolbar-segment-background-checked: #f0f; // Background of the Segment Buttons in the Toolbar
  // --ion-toolbar-segment-indicator-color: var(--ion-color-surface-background); // Color of the Segment Button indicator in the Toolbar

  --ion-item-background: var(--ion-color-surface-background); // Background of the Item
  // --ion-item-border-color: #fff; // Border color of the Item
  // --ion-item-color: #fff; // Color of the components in the Item

  // --ion-placeholder-color: #fff; // Color of the placeholder in Inputs

  // #endregion Application Colors

  // #region Application Variables
  --ion-font-family: 'Rubik', Arial, 'Helvetica Neue', sans-serif; // Font family of the app

  --ion-statusbar-padding: 10px; // Statusbar padding top of the app
  --ion-safe-area-top: env(safe-area-inset-top); // Adjust the safe area inset top of the app
  --ion-safe-area-bottom: env(safe-area-inset-bottom); // Adjust the safe area inset bottom of the app
  --ion-safe-area-left: env(safe-area-inset-left); // Adjust the safe area inset left of the app
  --ion-safe-area-right: env(safe-area-inset-right); // Adjust the safe area inset right of the app

  --ion-margin: 10px; // Adjust the margin of the Margin attributes
  --ion-padding: 10px; // Adjust the padding of the Padding attributes

  --ion-grid-width-xs: 100%;
  --ion-grid-width-sm: 100%;
  --ion-grid-width-md: 100%;
  --ion-grid-width-lg: 960px;
  --ion-grid-width-xl: 1140px;

  --ion-grid-columns: 12; // Number of columns in the grid
  --ion-grid-padding-xs: 0; // Padding of the grid for xs breakpoints
  --ion-grid-padding-sm: 0; // Padding of the grid for sm breakpoints
  --ion-grid-padding-md: 0; // Padding of the grid for md breakpoints
  --ion-grid-padding-lg: 0; // Padding of the grid for lg breakpoints
  --ion-grid-padding-xl: 0; // Padding of the grid for xl breakpoints
  --ion-grid-column-padding: var(--size-6px);
  // --ion-grid-column-padding-xs: 0; // Padding of the grid columns for xs breakpoints
  // --ion-grid-column-padding-sm: 0; // Padding of the grid columns for sm breakpoints
  // --ion-grid-column-padding-md: 0; // Padding of the grid columns for md breakpoints
  // --ion-grid-column-padding-lg: 0; // Padding of the grid columns for lg breakpoints
  // --ion-grid-column-padding-xl: 0; // Padding of the grid columns for xl breakpoints
  // #endregion Application Variables

  ion-button {
    text-transform: none;
    --box-shadow: none;
    --border-width: 1px;

    &:not([fill='clear']):not([fill='outline']) {
      --border-width: 1px;
      --border-style: solid;
      --border-color: var(--ion-color-primary-tint);

      &[color='primary'] {
        --border-color: var(--ion-color-primary-tint);
      }

      &[color='secondary'] {
        --border-color: var(--ion-color-secondary-tint);
      }

      &[color='tertiary'] {
        --border-color: var(--ion-color-tertiary-tint);
      }

      &[color='info'] {
        --border-color: var(--ion-color-info-tint);
      }

      &[color='success'] {
        --border-color: var(--ion-color-success-tint);
      }

      &[color='warning'] {
        --border-color: var(--ion-color-warning-tint);
      }

      &[color='danger'] {
        --border-color: var(--ion-color-danger-tint);
      }

      &[color='light'] {
        --border-color: var(--ion-color-light-tint);
      }

      &[color='medium'] {
        --border-color: var(--ion-color-medium-tint);
      }

      &[color='dark'] {
        --border-color: var(--ion-color-dark-tint);
      }

      &[color='white'] {
        --border-color: var(--ion-color-white-tint);
      }

      &[color='black'] {
        --border-color: var(--ion-color-black-tint);
      }

      &[color='brand-primary'] {
        --border-color: var(--ion-color-brand-primary-tint);
      }

      &[color='brand-primary-bg'] {
        --border-color: var(--ion-color-brand-primary-bg-tint);
      }

      &[color='main-header-bg'] {
        --border-color: var(--ion-color-main-header-bg-tint);
      }

      &[color='surface-background'] {
        --border-color: var(--ion-color-surface-background-tint);
      }
    }
  }

  ion-card {
    box-shadow: 0px 1px 10px -3px rgba(0, 0, 0, 0.1);
  }

  ion-segment-button {
    --background-checked: var(--ion-background-color-step-50);
    --indicator-color: var(--ion-color-surface-background);
  }

  ion-item-divider {
    --background: var(--ion-color-surface-background);
  }

  ion-header {
    box-shadow: none;
    border: none !important;
    border-bottom: 1px solid var(--ion-border-color);
  }

  ion-footer {
    box-shadow: none;
    border: none !important;
    border-top: 1px solid var(--ion-border-color);
  }

  ion-content {
    box-shadow: none;

    &::part(scroll) {
      scrollbar-width: thin;
    }
  }

  ion-datetime-button {
    &::part(native) {
      background: var(--ion-color-surface-background-shade);
    }
  }

  ion-datetime {
    --background: var(--ion-color-surface-background);
    --background-rgb: var(--ion-color-surface-background-rgb);
    --title-color: var(--ion-color-surface-background-contrast);

    &::part(month-year-button) {
      background-color: var(--ion-color-surface-background);
      color: var(--ion-color-surface-background-contrast);
    }

    &::part(time-button) {
      background-color: var(--ion-color-surface-background-shade);
      color: var(--ion-color-surface-background-contrast);
    }

    &::part(calendar-day today) {
      font-weight: bold;
      color: var(--ion-color-surface-background-contrast);
      border-color: var(--ion-color-primary-tint);
    }

    &::part(calendar-day active),
    &::part(calendar-day active):focus {
      background-color: var(--ion-color-primary);
      border-color: var(--ion-color-primary-tint);
      color: var(--ion-color-primary-contrast);
    }

    [slot='time-label'] {
      color: var(--ion-color-surface-background-contrast);
    }
  }

  ion-card {
    color: var(--ion-color-surface-background-contrast);

    ion-card-title,
    ion-card-subtitle {
      color: var(--ion-color-surface-background-contrast);
    }

    &[color='primary'] {
      color: var(--ion-color-primary-contrast);
    }

    &[color='secondary'] {
      color: var(--ion-color-secondary-contrast);
    }

    &[color='tertiary'] {
      color: var(--ion-color-tertiary-contrast);
    }

    &[color='info'] {
      color: var(--ion-color-info-contrast);
    }

    &[color='success'] {
      color: var(--ion-color-success-contrast);
    }

    &[color='warning'] {
      color: var(--ion-color-warning-contrast);
    }

    &[color='danger'] {
      color: var(--ion-color-danger-contrast);
    }

    &[color='light'] {
      color: var(--ion-color-light-contrast);
    }

    &[color='medium'] {
      color: var(--ion-color-medium-contrast);
    }

    &[color='dark'] {
      color: var(--ion-color-dark-contrast);
    }

    &[color='white'] {
      color: var(--ion-color-white-contrast);
    }

    &[color='black'] {
      color: var(--ion-color-black-contrast);
    }

    &[color='brand-primary'] {
      color: var(--ion-color-brand-primary-contrast);
    }

    &[color='brand-primary-bg'] {
      color: var(--ion-color-brand-primary-bg-contrast);
    }

    &[color='main-header-bg'] {
      color: var(--ion-color-main-header-bg-contrast);
    }

    &[color='surface-background'] {
      color: var(--ion-color-surface-background-contrast);
    }
  }

  ion-note {
    --color: var(--ion-text-color-step-500);
  }

  ion-searchbar {
    padding: 0;
    min-height: auto;
  }
}

.ion-color-white {
  --ion-color-base: var(--ion-color-white);
  --ion-color-base-rgb: var(--ion-color-white-rgb);
  --ion-color-contrast: var(--ion-color-white-contrast);
  --ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
  --ion-color-shade: var(--ion-color-white-shade);
  --ion-color-tint: var(--ion-color-white-tint);
}

.ion-color-black {
  --ion-color-base: var(--ion-color-black);
  --ion-color-base-rgb: var(--ion-color-black-rgb);
  --ion-color-contrast: var(--ion-color-black-contrast);
  --ion-color-contrast-rgb: var(--ion-color-black-contrast-rgb);
  --ion-color-shade: var(--ion-color-black-shade);
  --ion-color-tint: var(--ion-color-black-tint);
}

.ion-color-info {
  --ion-color-base: var(--ion-color-info);
  --ion-color-base-rgb: var(--ion-color-info-rgb);
  --ion-color-contrast: var(--ion-color-info-contrast);
  --ion-color-contrast-rgb: var(--ion-color-info-contrast-rgb);
  --ion-color-shade: var(--ion-color-info-shade);
  --ion-color-tint: var(--ion-color-info-tint);
}

.ion-color-brand-primary {
  --ion-color-base: var(--ion-color-brand-primary);
  --ion-color-base-rgb: var(--ion-color-brand-primary-rgb);
  --ion-color-contrast: var(--ion-color-brand-primary-contrast);
  --ion-color-contrast-rgb: var(--ion-color-brand-primary-contrast-rgb);
  --ion-color-shade: var(--ion-color-brand-primary-shade);
  --ion-color-tint: var(--ion-color-brand-primary-tint);
}

.ion-color-brand-primary-bg {
  --ion-color-base: var(--ion-color-brand-primary-bg);
  --ion-color-base-rgb: var(--ion-color-brand-primary-bg-rgb);
  --ion-color-contrast: var(--ion-color-brand-primary-bg-contrast);
  --ion-color-contrast-rgb: var(--ion-color-brand-primary-bg-contrast-rgb);
  --ion-color-shade: var(--ion-color-brand-primary-bg-shade);
  --ion-color-tint: var(--ion-color-brand-primary-bg-tint);
}

.ion-color-main-header-bg {
  --ion-color-base: var(--ion-color-main-header-bg);
  --ion-color-base-rgb: var(--ion-color-main-header-bg-rgb);
  --ion-color-contrast: var(--ion-color-main-header-bg-contrast);
  --ion-color-contrast-rgb: var(--ion-color-main-header-bg-contrast-rgb);
  --ion-color-shade: var(--ion-color-main-header-bg-shade);
  --ion-color-tint: var(--ion-color-main-header-bg-tint);
}

.ion-color-surface-background {
  --ion-color-base: var(--ion-color-surface-background);
  --ion-color-base-rgb: var(--ion-color-surface-background-rgb);
  --ion-color-contrast: var(--ion-color-surface-background-contrast);
  --ion-color-contrast-rgb: var(--ion-color-surface-background-contrast-rgb);
  --ion-color-shade: var(--ion-color-surface-background-shade);
  --ion-color-tint: var(--ion-color-surface-background-tint);
}

//////////////////// DARK MODE

body.dark,
.md body.dark,
.ios body.dark {
  color-scheme: dark; // https://www.amitmerchant.com/darkmode-aware-scrollbars-using-css/

  --ion-color-primary: #2b4eee;
  --ion-color-primary-rgb: 43, 78, 238;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #2645d1;
  --ion-color-primary-tint: #4060f0;
  --ion-color-primary-lighten: #678aff;

  --ion-color-secondary: #2196f3;
  --ion-color-secondary-rgb: 33, 150, 243;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #1d84d6;
  --ion-color-secondary-tint: #37a1f4;

  --ion-color-tertiary: #805dca;
  --ion-color-tertiary-rgb: 128, 93, 202;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #7152b2;
  --ion-color-tertiary-tint: #8d6dcf;

  --ion-color-success: #00ab55;
  --ion-color-success-rgb: 0, 171, 85;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #00964b;
  --ion-color-success-tint: #1ab366;

  --ion-color-info: #2196f3;
  --ion-color-info-rgb: 33, 150, 243;
  --ion-color-info-contrast: #000000;
  --ion-color-info-contrast-rgb: 0, 0, 0;
  --ion-color-info-shade: #1d84d6;
  --ion-color-info-tint: #37a1f4;

  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  --ion-color-danger: #e92b44;
  --ion-color-danger-rgb: 233, 43, 68;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cd263c;
  --ion-color-danger-tint: #eb4057;

  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  --ion-color-light: #ececec;
  --ion-color-light-rgb: 245, 248, 229;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d8daca;
  --ion-color-light-tint: #f6f9e8;

  // #region Custom colors
  --ion-color-white: #ffffff;
  --ion-color-white-rgb: 255, 255, 255;
  --ion-color-white-contrast: #000000;
  --ion-color-white-contrast-rgb: 0, 0, 0;
  --ion-color-white-shade: #e0e0e0;
  --ion-color-white-tint: #ffffff;

  --ion-color-black: #000000;
  --ion-color-black-rgb: 0, 0, 0;
  --ion-color-black-contrast: #ffffff;
  --ion-color-black-contrast-rgb: 255, 255, 255;
  --ion-color-black-shade: #000000;
  --ion-color-black-tint: #1a1a1a;

  --ion-color-brand-primary: #f6ff00;
  --ion-color-brand-primary-rgb: 246, 255, 0;
  --ion-color-brand-primary-contrast: #000000;
  --ion-color-brand-primary-contrast-rgb: 0, 0, 0;
  --ion-color-brand-primary-shade: #d8e000;
  --ion-color-brand-primary-tint: #f7ff1a;

  --ion-color-brand-primary-bg: #0e172f;
  --ion-color-brand-primary-bg-rgb: 14, 23, 47;
  --ion-color-brand-primary-bg-contrast: #ffffff;
  --ion-color-brand-primary-bg-contrast-rgb: 255, 255, 255;
  --ion-color-brand-primary-bg-shade: #0c1429;
  --ion-color-brand-primary-bg-tint: #262e44;

  --ion-color-main-header-bg: #0f0c1f;
  --ion-color-main-header-bg-rgb: 15, 12, 31;
  --ion-color-main-header-bg-contrast: #ffffff;
  --ion-color-main-header-bg-contrast-rgb: 255, 255, 255;
  --ion-color-main-header-bg-shade: #0d0b1b;
  --ion-color-main-header-bg-tint: #272435;

  --ion-color-surface-background: #120f24;
  --ion-color-surface-background-rgb: 18, 15, 36;
  --ion-color-surface-background-contrast: #ffffff;
  --ion-color-surface-background-contrast-rgb: 255, 255, 255;
  --ion-color-surface-background-shade: #100d20;
  --ion-color-surface-background-tint: #2a273a;
  // #endregion Custom colors

  // #region Stepped Colors
  // https://ionicframework.com/docs/theming/themes#stepped-color-generator
  // TODO: Find all variables that start with the 'var(--ion-color-step-..)' and replace them with the newly added variables

  --ion-background-color: #151227; // Background color of the entire app
  --ion-background-color-rgb: 249, 37, 11; // Background color of the entire app, rgb format

  --ion-text-color: #fff; // Text color of the entire app
  --ion-text-color-rgb: 255, 255, 255; // Text color of the entire app, rgb format

  --ion-color-step-50: #181c2e;
  --ion-color-step-100: #242839;
  --ion-color-step-150: #303444;
  --ion-color-step-200: #3d404f;
  --ion-color-step-250: #494c5a;
  --ion-color-step-300: #555865;
  --ion-color-step-350: #616470;
  --ion-color-step-400: #6d707b;
  --ion-color-step-450: #797c86;
  --ion-color-step-500: #868891;
  --ion-color-step-550: #92939c;
  --ion-color-step-600: #9e9fa7;
  --ion-color-step-650: #aaabb2;
  --ion-color-step-700: #b6b7bd;
  --ion-color-step-750: #c2c3c8;
  --ion-color-step-800: #cecfd3;
  --ion-color-step-850: #dbdbde;
  --ion-color-step-900: #e7e7e9;
  --ion-color-step-950: #f3f3f4;

  --ion-text-color-step-50: #f3f3f4;
  --ion-text-color-step-100: #e7e7e9;
  --ion-text-color-step-150: #dbdbde;
  --ion-text-color-step-200: #cecfd3;
  --ion-text-color-step-250: #c2c3c8;
  --ion-text-color-step-300: #b6b7bd;
  --ion-text-color-step-350: #aaabb2;
  --ion-text-color-step-400: #9e9fa7;
  --ion-text-color-step-450: #92939c;
  --ion-text-color-step-500: #868891;
  --ion-text-color-step-550: #797c86;
  --ion-text-color-step-600: #6d707b;
  --ion-text-color-step-650: #616470;
  --ion-text-color-step-700: #555865;
  --ion-text-color-step-750: #494c5a;
  --ion-text-color-step-800: #3d404f;
  --ion-text-color-step-850: #303444;
  --ion-text-color-step-900: #242839;
  --ion-text-color-step-950: #181c2e;

  --ion-background-color-step-50: #181c2e;
  --ion-background-color-step-100: #242839;
  --ion-background-color-step-150: #303444;
  --ion-background-color-step-200: #3d404f;
  --ion-background-color-step-250: #494c5a;
  --ion-background-color-step-300: #555865;
  --ion-background-color-step-350: #616470;
  --ion-background-color-step-400: #6d707b;
  --ion-background-color-step-450: #797c86;
  --ion-background-color-step-500: #868891;
  --ion-background-color-step-550: #92939c;
  --ion-background-color-step-600: #9e9fa7;
  --ion-background-color-step-650: #aaabb2;
  --ion-background-color-step-700: #b6b7bd;
  --ion-background-color-step-750: #c2c3c8;
  --ion-background-color-step-800: #cecfd3;
  --ion-background-color-step-850: #dbdbde;
  --ion-background-color-step-900: #e7e7e9;
  --ion-background-color-step-950: #f3f3f4;
  // #endregion Stepped Colors

  // #region Application Colors
  // https://ionicframework.com/docs/theming/themes#application-colors
  --ion-backdrop-color: #676573; // var(--ion-background-color); // Color of the Backdrop component
  --ion-backdrop-opacity: 0.9; // Opacity of the Backdrop component
  --ion-overlay-background-color: var(--ion-background-color-step-50); // Background color of the overlays

  --ion-card-background: var(--ion-color-surface-background);
  --ion-border-color: var(--ion-background-color-step-100); // Border color
  // --ion-box-shadow-color: var(--ion-background-color-step-50); // Box shadow color

  --ion-tab-bar-background: var(--ion-background-color-step-50); // Background of the Tab Bar
  // --ion-tab-bar-background-focused: #fff; // Background of the focused Tab Bar
  // --ion-tab-bar-border-color: #fff; // Border color of the Tab Bar
  // --ion-tab-bar-color: #fff; // Color of the Tab Bar
  // --ion-tab-bar-color-selected: #fff; // Color of the selected Tab Button

  --ion-toolbar-background: var(--ion-color-surface-background); // Background of the Toolbar
  // --ion-toolbar-border-color: #fff; // Border color of the Toolbar
  // --ion-toolbar-color: #fff; // Color of the components in the Toolbar

  // --ion-toolbar-segment-color: #f00; // Color of the Segment Buttons in the Toolbar
  // --ion-toolbar-segment-color-checked: #f00; // Color of the checked Segment Buttons in the Toolbar
  // --ion-toolbar-segment-background: #f00; // Background of the Segment Buttons in the Toolbar
  // --ion-toolbar-segment-background-checked: #f0f; // Background of the Segment Buttons in the Toolbar
  // --ion-toolbar-segment-indicator-color: var(--ion-color-surface-background); // Color of the Segment Button indicator in the Toolbar

  --ion-item-background: var(--ion-color-surface-background); // Background of the Item
  // --ion-item-border-color: #fff; // Border color of the Item
  // --ion-item-color: #fff; // Color of the components in the Item

  // --ion-placeholder-color: #fff; // Color of the placeholder in Inputs
  // #endregion Application Colors

  ion-button {
    &[fill='clear'],
    &[fill='outline'] {
      &:not([color]) {
        // $base-color: #{var(--ion-color-primary)};
        --color: var(--ion-color-primary-lighten);
      }
    }
  }

  ion-accordion {
    ion-item[slot='header'] {
      --background: var(--ion-color-primary);
      --color: var(--ion-color-primary-contrast);
    }
  }

  ion-card {
    box-shadow: 0px 1px 10px -3px rgba(0, 0, 0, 0.1);
    border: 1px solid var(--ion-background-color-step-100);
  }

  ion-segment-button {
    --background-checked: var(--ion-background-color-step-50);
    --indicator-color: var(--ion-color-surface-background-shade);
  }

  ion-item-divider {
    --background: var(--ion-color-surface-background);
  }

  ion-header {
    box-shadow: none;
    border: none !important;
    border-bottom: 1px solid var(--ion-border-color);
  }

  ion-footer {
    box-shadow: none;
    border: none !important;
    border-top: 1px solid var(--ion-border-color);
  }

  ion-content {
    box-shadow: none;
  }
}
