@font-face {
  font-family: 'Rubik';
  src:
    url('/fonts/Rubik-Regular.woff2') format('woff2'),
    url('/fonts/Rubik-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Rubik';
  src:
    url('/fonts/Rubik-SemiBold.woff2') format('woff2'),
    url('/fonts/Rubik-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Rubik';
  src:
    url('/fonts/Rubik-Bold.woff2') format('woff2'),
    url('/fonts/Rubik-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Rubik';
  src:
    url('/fonts/Rubik-ExtraBold.woff2') format('woff2'),
    url('/fonts/Rubik-ExtraBold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Rubik';
  src:
    url('/fonts/Rubik-Black.woff2') format('woff2'),
    url('/fonts/Rubik-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
