@use 'sass:math';
@use 'sass:map';

@use 'bp-shared-styles/dist/scss/breakpoints/mixins' as bpMxBreakpoints;

@use '../partials/variables.scss' as vars;

/* #region Breakpoints */
@mixin mx-min-width($breakpoint, $breakpoints: vars.$tb-breakpoints) {
  // If $breakpoint is a key that exists in
  // $breakpoints, get and use the value
  @if map-has-key($breakpoints, $breakpoint) {
    $breakpoint: map-get($breakpoints, $breakpoint);
  }

  @media screen and (min-width: #{$breakpoint}) {
    @content;
  }
}

// Mixin for max-width media queries
@mixin mx-max-width($breakpoint, $breakpoints: vars.$tb-breakpoints) {
  // If $breakpoint is a key that exists in
  // $breakpoints, get and use the value
  @if map-has-key($breakpoints, $breakpoint) {
    $breakpoint: map-get($breakpoints, $breakpoint);
  }

  @media screen and (max-width: #{$breakpoint - 1px}) {
    @content;
  }
}
/* #endregion */

/* #region Buttons */
@mixin mx-rounded-btn(
  $width-mobile,
  $height-mobile,
  $width-desktop,
  $height-desktop,
  $color,
  $background-color,
  $border-color,
  $border-width,
  $border-radius-mobile,
  $border-radius-desktop
) {
  min-height: auto;
  box-sizing: border-box;
  margin: 0;

  @if $color {
    --color: #{$color};
  }

  --background: #{$background-color};
  --border-color: #{$border-color} !important; // TODO: Remove !important when possible
  --border-width: #{$border-width} !important;

  @include bpMxBreakpoints.desktop-down {
    --border-radius: #{$border-radius-mobile};
    --padding-bottom: 0;
    --padding-start: 0;
    --padding-end: 0;
    --padding-top: 0;
    height: $height-mobile;
    width: $width-mobile;
  }

  @include bpMxBreakpoints.desktop-up {
    height: $height-desktop;
    width: $width-desktop;
    margin: 0;
    --border-radius: #{$border-radius-desktop};
  }

  .ph {
    @if $color {
      color: #{$color};
    }
  }
}
/* #endregion */
/* #region Layout */
@mixin mx-ion-page-styles {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  contain: layout size style;
  z-index: 0;
}

@mixin mx-set-header-main-or-footer($header-class, $main-class, $footer-class) {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  @if ($header-class) {
    .#{$header-class} {
      box-sizing: border-box;
      flex: 0 0 auto;
    }

    .#{$main-class} {
      box-sizing: border-box;
      flex: 1 0 auto;
    }

    .#{$footer-class} {
      box-sizing: border-box;
      margin-top: auto;
    }
  }
}

@mixin mx-main-header-height() {
  height: 60px;

  @include bpMxBreakpoints.desktop-up {
    height: 80px;
  }
}
/* #endregion */

/* #region Main Header Nav List  */
@mixin mx-main-header-nav-list {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  z-index: 1; /* Ensure dropdown is above other elements */
  box-sizing: border-box;
  margin: 0;
  padding: 0;

  > li {
    box-sizing: border-box;
    padding: 5px 15px;
    cursor: pointer;
    color: #9ea7d3;
    height: 80px;
    display: flex;

    > a {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 6px;
      white-space: nowrap; /* Prevent text wrapping */
      overflow: hidden; /* Hide excess text if it doesn't fit */
      text-overflow: ellipsis; /* Add ellipsis for overflown text */
      box-sizing: border-box;
      text-decoration: none;
      color: currentColor;
      text-transform: uppercase;
      font-size: 16px;
      font-weight: bold;
    }

    &:last-child {
      border-bottom: none;
    }

    &:hover {
      color: #fff;
    }

    &.is-active {
      position: relative;
      color: #fff;

      &::before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: auto;
        height: 3px;
        background-color: #026cff;
      }
    }
  }
}
/* #endregion */
