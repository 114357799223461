/* You can add global styles to this file, and also import other style files */
//
//// IONIC STYLES
//
/* Core CSS required for Ionic components to work properly */
@use '@ionic/angular/css/core.css';
/* Basic CSS for apps built with Ionic */
@use '@ionic/angular/css/normalize.css';
@use '@ionic/angular/css/structure.css';
@use '@ionic/angular/css/typography.css';
/* Optional CSS utils that can be commented out */
@use '@ionic/angular/css/padding.css';
@use '@ionic/angular/css/float-elements.css';
@use '@ionic/angular/css/text-alignment.css';
@use '@ionic/angular/css/text-transformation.css';
@use '@ionic/angular/css/flex-utils.css';
@use '@ionic/angular/css/display.css';

@use './theme/partials/variables' as vars;
@use './theme/partials/mixins' as mixins;
@use './theme/ag-grid-customization.scss' as agGridCustomization;

@use 'bp-shared-styles/dist/scss/breakpoints/utils' as utils;
@use 'bp-shared-styles/dist/scss/breakpoints/mixins' as bpMxBreakpoints;

@import '@phosphor-icons/web/regular';
@import url('https://fonts.googleapis.com/css2?family=Ubuntu+Sans:ital,wght@0,400..700;1,100..700&display=swap');

@import 'ag-grid-community/styles/ag-grid.css';
/* Quartz Theme Specific CSS */
@import 'ag-grid-community/styles/ag-theme-quartz.css';

.ag-theme-quartz {
  @include agGridCustomization.mx-ag-grid-customization;
}

// //// CUSTOM - GLOBAL STYLES
//
.ph {
  font-size: inherit;
}

p {
  font-size: var(--font-size-default);
}

.has-w340 {
  --width: 340px;
}

.hide-on-mobile {
  display: none;

  @include mixins.mx-min-width(map-get(vars.$tb-breakpoints, desktop)) {
    display: initial;
  }
}

.hide-on-desktop {
  display: initial;

  @include mixins.mx-min-width(map-get(vars.$tb-breakpoints, desktop)) {
    display: none;
  }
}

.full-screen-modal {
  --width: 100%;
  --height: 100%;
}

.sign-up-sign-in-modal {
  @include bpMxBreakpoints.desktop-down {
    --width: 100%;
    --height: 100%;
  }

  @include bpMxBreakpoints.desktop-up {
    --min-height: 90%;
  }
}

.is-casino-search-modal {
  --width: 100%;
  --backdrop-opacity: 0;
  --background: rgba(0, 0, 0, 0);
}

ion-toolbar {
  &[dirToolbarAllowOverflow] {
    contain: none !important;
    overflow: visible !important;
  }
}

ion-searchbar {
  &.casino-searchbar {
    .searchbar-input-container {
      .searchbar-input {
        min-height: 44px;
        padding-inline-start: 36px !important;
      }
    }
  }
}
