$tb-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  sd: 640px,
  hd: 1280px,
  fhd: 1920px,
  qhd: 2560px,
  2k: 2048px,
  4k: 3840px,
  tablet: 768px,
  desktop: 1280px,
  wide_desktop: 1920px
);

$tb-sizes: (
  tiny: 1rem,
  mini: 1.25rem,
  small: 1.5rem,
  medium: 2rem,
  large: 2.375rem,
  big: 3rem,
  huge: 3.5rem
);

:root {
  --size-1px: 0.0625rem;
  --size-2px: 0.125rem;
  --size-3px: 0.1875rem;
  --size-4px: 0.25rem;
  --size-5px: 0.3125rem;
  --size-6px: 0.375rem;
  --size-8px: 0.5rem;
  --size-9px: 0.563rem;
  --size-11px: 0.688rem;
  --size-10px: 0.625rem;
  --size-11px: 0.688rem;
  --size-12px: 0.75rem;
  --size-14px: 0.875rem;
  --size-15px: 0.9375rem;
  --size-16px: 1rem;
  --size-18px: 1.125rem;
  --size-20px: 1.25rem;
  --size-22px: 1.375rem;
  --size-24px: 1.5rem;
  --size-25px: 1.5625rem;
  --size-28px: 1.75rem;
  --size-32px: 2rem;
  --size-36px: 2.25rem;
  --size-38px: 2.375rem;
  --size-40px: 2.5rem;
  --size-44px: 2.75rem;
  --size-48px: 3rem;
  --size-50px: 3.125rem;
  --size-56px: 3.5rem;
  --size-64px: 4rem;
  --size-72px: 4.5rem;
  --size-74px: 4.625rem;
  --size-80px: 5rem;
  --size-90px: 5.625rem;
  --size-100px: 6.25rem;

  --size-tiny: 1rem;
  --size-mini: 1.25rem;
  --size-small: 1.5rem;
  --size-default: 2rem;
  --size-medium: 2rem;
  --size-large: 2.375rem;
  --size-big: 3rem;
  --size-huge: 3.5rem;

  --spacing-tiny: 0.125rem;
  --spacing-mini: 0.25rem;
  --spacing-small: 0.375rem;
  --spacing-default: 0.625rem;
  --spacing-medium: 0.625rem;
  --spacing-large: 1rem;
  --spacing-big: 1.25rem;
  --spacing-huge: 1.5rem;

  /* Border */

  /* Header */
  --tmp-header-btn-font-size-mobile: 12px;
  --tmp-header-btn-font-size-desktop: 14px;
  --tmp-header-btn-size-mobile: 34px;
  --tmp-header-btn-size-desktop: 44px;
  --tmp-header-btn-color: #eaecf2;
  --tmp-header-btn-border-color: #34314c;
  --tmp-header-btn-border-width: 1px;
  --tmp-header-btn-background-color: #151128;

  --tmp-header-deposit-btn-border-color: #feb005;
  --tmp-header-deposit-btn-border-width: 2px;
  --tmp-header-deposit-btn-background-color: #fe7805;
  --tmp-header-deposit-btn-border-radius-desktop: 22px;

  --tmp-header-user-btn-background-color: #151128;
  --tmp-header-user-btn-border-color: #026cff;
  --tmp-header-user-btn-border-width: 3px;
}
